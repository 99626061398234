<template>
  <div>
    <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
        <v-container fluid>
                <v-row >
                        <v-col class="d-flex" cols="12" sm="6">
                            <v-select
                            :items="tclist"
                            label="Tenant"
                            v-model="selected_tenant"
                            @change="fetchorganization()"></v-select>
                        </v-col>
                </v-row>
        </v-container>
        <v-container fluid style="display:none" id="orgdiv">
                <v-row >
                        <v-col class="d-flex" cols="10" sm="6">
                            <v-select
                            :items="orglist"
                            label="Organization"
                            v-model="selected_org"
                            
                            @change="fetchstatus()"></v-select>
                        </v-col>
                        <v-col cols="2" sm="6">
                          <v-btn small color="primary" @click="pushmaster()" id="pushbtn" :loading="!loading">Push</v-btn>
                        </v-col>
                </v-row>
        </v-container>
<template>
  <v-card>
    <v-card-title>
      Super Master to Master Push Status
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="master_status"
      :search="search"
    ></v-data-table>
  </v-card>
</template>
{{message}}
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
        search: '',
        tclist:'',
        message:'',
        snackbar_msg: "",
        color: "",
        snackbar: false,
        loading:true,
        selected_tenant:'',
        orglist:'',
        selected_org:'',
        master_status:'',
         headers: [
      { text: "Supermaster", value: "super", sortable: true },
      { text: "Master", value: "tenant", sortable: true },
      { text: "Status", value: "status", sortable: true },
      
      ]


  }),

  
  mounted() {
    const data={
            //uid:this.username    
        }
        axios.post('/Admin/startCopy')
        .then(res=>{
                // window.console.log(res)
                if(res.data.msg=="200")
                {
                    
                    this.tclist=res.data.tenants
                    //this.domainpath=res.data.domainpath
                    

                }
                else{   
                   
                    this.message=res.data.msg
                }
    })
    .catch(error=>{
                window.console.log("error:"+error)
            })
  

  },

  methods: {
    fetchorganization(){
const data={
            tenantid:this.selected_tenant    
        }
        axios.post('/Admin/fetchOrganization',data)
        .then(res=>{
                // window.console.log(res)
                if(res.data.msg=="200")
                {
                    
                    this.orglist=res.data.org
                    //this.orglist.name=res.data.org.name
                    document.getElementById('orgdiv').style.display="block"
                    //this.domainpath=res.data.domainpath
                    

                }
                else{   
                   
                    this.message=res.data.msg
                }
    })
    .catch(error=>{
                window.console.log("error:"+error)
            })
  
  
  },
  showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
fetchstatus(){
const data={
            tenantid:this.selected_tenant ,   
            orgid:this.selected_org    
        }
        //alert('status')
        axios.post('/Admin/fetchmasterstaus',data)
        .then(res=>{
                // window.console.log(res)
                if(res.data.msg=="200")
                {
                    
                    this.master_status=res.data.status
                    //this.orglist.name=res.data.org.name
                    //document.getElementById('orgdiv').style.display="block"
                    //this.domainpath=res.data.domainpath
                    

                }
                else{   
                   
                    this.message=res.data.msg
                }
    })
    .catch(error=>{
                window.console.log("error:"+error)
            })
  
  
  },
  pushmaster(){
    this.loading=false;
const data={
            tenant:this.selected_tenant ,   
            org:this.selected_org    
        }
        //alert('status')
        axios.post('/Admin/copy',data)
        .then(res=>{
                // window.console.log(res)
                if(res.data.msg=="200")
                {
                    
                    this.message=res.data.msg
                    this.loading=true;
                    this.showSnackbar("#4caf50", "Pushed Successfully...");
                    //this.orglist.name=res.data.org.name
                    //document.getElementById('orgdiv').style.display="block"
                    //this.domainpath=res.data.domainpath
                    

                }
                else{   
                   
                    this.message=res.data.msg
                }
    })
    .catch(error=>{
                window.console.log("error:"+error)
            })
  
  
  }
   
  }
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
</style>